import { socioGrpcClient } from "@/setup/socioGrpcClient";
import {
  SiteAsset,
  BuildingAsset,
  AssetTag,
  StoreyAsset,
  ZoneAsset,
  SpaceAsset,
  ComponentAsset,
  SubcomponentAsset,
  AosObservable,
} from "@/models";
import { ASSET_ITEMS } from "@/utils/assetItems";
import {
  formatAndInsertAssets,
  formatAndInsertObservables,
} from "@/utils/response";
import { useAosEventBus, utils } from "@socotec.io/socio-aos-component";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { useAosObservableReplication } from "@/rxdbWorker/replicationProtocol/useAosObservableReplication.js";
import { useRxdbCollection } from "../../composables/useRxdbCollection";
import { convertMapToArray } from "@/rxdb/utils.js";
import { cloneDeep } from "lodash";
const amosPerimeterApi = socioGrpcClient.amos_back.aos;
const amosPerimeterClient = amosPerimeterApi.PerimeterControllerPromiseClient;

const state = {
  tagsSelected: [],
  perimeterLoadingInReplication: true,

  // these state proprerties are intermediate tables that help make queries more efficient
  aosIdToAssetIdMap: {},
  pathToAssetIdMap: {},
  assetToObservableMap: {},
};

const getters = {
  getAllAssets: () => {
    const assetsByAosId = {};
    const pathsMapping = {};

    for (const assetItemKey in ASSET_ITEMS) {
      const items = ASSET_ITEMS[assetItemKey].modelClass().query().all();

      items.forEach((item) => {
        assetsByAosId[item.aosItem] = item;
        pathsMapping[item.path] = item.aosItem;
      });
    }

    return {
      assets: assetsByAosId,
      pathsMapping,
    };
  },
  retrieveAssetFromAosItem: (state) => (assetType, aosItemUuid) => {
    const assetUuid = state.aosIdToAssetIdMap[aosItemUuid];
    const { modelClass } = ASSET_ITEMS[assetType];
    const assetItem = modelClass().find(assetUuid);
    return assetItem;
  },
  getAosItemsUuidsFromAssets: () => {
    let allAssetUuidsByStructure = {};

    Object.keys(ASSET_ITEMS).forEach((assetItemKey) => {
      allAssetUuidsByStructure[assetItemKey] = ASSET_ITEMS[assetItemKey]
        .modelClass()
        .query()
        .get()
        .map((asset) => asset.aosItem);
    });

    return allAssetUuidsByStructure;
  },
  getProjectPerimeterUuid: (state) => state.projectPerimeterUuid,
};

const actions = {
  async fetchAssets({ commit, dispatch, rootGetters }, params = {}) {
    try {
      await dispatch("fetchProjectPerimeter", params);
      await dispatch("fetchAmosData", {
        projectPerimeter:
          params.projectPerimeter ?? rootGetters["project/getProjectPerimeter"],
      });
    } catch (error) {
      console.error(error);
    }

    // INFO - MS - 17/11/2O22 - can't use useAosEventBus because
    // AosPanel not mounted to listen on events when fetchAssets is called
    commit(
      "aos/SET_AOS_REQUEST_FILTER",
      { uuids: getters.getAosItemsUuidsFromAssets() },
      { root: true }
    );
  },

  // MS - INFO - 24/07/24 - Caution ! No logic for insert in rxdb
  async fetchAmosData({ dispatch, rootGetters }, params = {}) {
    const projectPerimeter =
      params?.projectPerimeter ?? rootGetters["project/getProjectPerimeter"];

    if (!projectPerimeter) return;

    const filters = (pageSize) => ({
      metadata: {
        filters: JSON.stringify({
          project_perimeter: projectPerimeter,
        }),
        pagination: JSON.stringify({ page_size: pageSize }),
      },
      listAll: true,
    });

    // MS - INFO - 24/07/24 - Caution ! SiteAsset, BuildingAsset, etc., pulled on the app start
    // but no logic for upsert in rxdb if this action is recalled elsewhere
    const site = await dispatch("site/fetchSites", filters(10), { root: true });
    const building = await dispatch("building/fetchBuildings", filters(10), {
      root: true,
    });
    const storey = await dispatch("storey/fetchStoreys", filters(100), {
      root: true,
    });
    const zone = await dispatch("zone/fetchZones", filters(200), {
      root: true,
    });
    const space = await dispatch("space/fetchSpaces", filters(200), {
      root: true,
    });
    const component = await dispatch(
      "component/fetchComponents",
      filters(1000),
      { root: true }
    );

    return { site, building, storey, zone, space, component };
  },

  async fetchProjectPerimeter({ commit, getters }, params) {
    const { execOnCollection: execOnPerimeter } =
      useRxdbCollection("user_perimeter");
    let perimeter = await execOnPerimeter((c) =>
      c
        .findOne({ selector: { projectId: params.projectUuid, userId: null } })
        .exec()
    );
    while (!perimeter) {
      await new Promise((resolve) => setTimeout(resolve, 200));
      perimeter = await execOnPerimeter((c) =>
        c
          .findOne({
            selector: { projectId: params.projectUuid, userId: null },
          })
          .exec()
      );
    }

    const { execOnCollection: execOnAosObs } =
      useRxdbCollection("aos_observable");
    let aosObservableList = perimeter.aosObservableUuids?.length
      ? convertMapToArray(
          await execOnAosObs((c) =>
            c.findByIds(perimeter.aosObservableUuids).exec()
          )
        )
      : [];
    let safeCount = 0;
    if (!aosObservableList.length && perimeter.aosObservableUuids?.length) {
      while (!aosObservableList.length && safeCount < 30) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        aosObservableList = (await perimeter.aosObservableUuids?.length)
          ? convertMapToArray(
              await execOnAosObs((c) =>
                c.findByIds(perimeter.aosObservableUuids).exec()
              )
            )
          : [];
        safeCount += 1;
      }
    }
    if (safeCount === 30)
      console.error("Error while loading observable from rxdb");

    await formatAndInsertAssets(aosObservableList.map((e) => e.toJSON()));

    commit(
      "aos/SET_AOS_REQUEST_FILTER",
      { uuids: getters.getAosItemsUuidsFromAssets },
      { root: true }
    );
  },

  async addAssetsToLocalPerimeter({ commit, rootGetters }, assetsData) {
    const { insertVisitPerimeter } = useAosObservableReplication();

    const { execOnCollection: execOnPerimeter } =
      useRxdbCollection("user_perimeter");

    const localPerimeter = await execOnPerimeter((c) =>
      c
        .findOne({
          selector: {
            projectId: rootGetters["project/getProjectUuid"],
            userId: null,
          },
        })
        .exec()
    );
    const copyPerimeter = cloneDeep(localPerimeter.toJSON());

    if (assetsData.components) {
      const { execOnCollection: execOnAosObs } =
        useRxdbCollection("aos_observable");
      const localComponents = assetsData.components;
      const aosObservableWithSameAosItem = await execOnAosObs((c) =>
        c
          .find({
            selector: {
              aosItem: { $in: localComponents.map(({ uuid }) => uuid) },
            },
          })
          .exec()
      );

      const aosItemUuid = aosObservableWithSameAosItem.map(
        ({ aosItem }) => aosItem
      );
      let localAosObservableList = [];
      let localComponentAsset = [];
      for (const component of localComponents.filter(
        (c) => !aosItemUuid.includes(c.uuid)
      )) {
        const localDate = dayjs().format("YYYY-MM-DDTHH:mm:ssZ");
        const newAsset = {
          uuid: uuidv4(),
          site: component.site,
          code: component.code,
          observationComment: component.observationComment,
          building: component.building,
          aosItem: component.uuid,
          path: component.path,
        };
        const newAosObservable = {
          uuid: uuidv4(),
          updatedAt: localDate,
          createdAt: localDate,
          displayed: true,
          assetType: "component",
          assetModel: "componentAssets",
          assetUuid: newAsset.uuid,
          aosItem: component.uuid,
          createdOrUpdatedOffline: true,
        };

        localAosObservableList.push(newAosObservable);
        localComponentAsset.push(newAsset);
        copyPerimeter.aosItemUuids.push(newAsset.uuid);
        copyPerimeter.aosObservableUuids.push(newAosObservable.uuid);
        if (!copyPerimeter.aosItemUuidCollection.component)
          copyPerimeter.aosItemUuidCollection.component = [];
        copyPerimeter.aosItemUuidCollection.component.push(component.uuid);
        if (!copyPerimeter.assetUuidCollection.component)
          copyPerimeter.assetUuidCollection.component = [];
        copyPerimeter.assetUuidCollection.component.push(newAsset.uuid);

        copyPerimeter.updatedAt = localDate;
        copyPerimeter.createdOrUpdatedOffline = true;
      }

      const { execOnCollection: execOnAsset } =
        useRxdbCollection("component_asset");

      await execOnAsset((c) => c.bulkUpsert(localComponentAsset));

      await execOnAosObs((c) => c.bulkUpsert(localAosObservableList));

      await AosObservable.insertOrUpdate({ data: localAosObservableList });
      await ComponentAsset.insertOrUpdate({ data: localComponentAsset });

      await insertVisitPerimeter(copyPerimeter);
      commit("SET_PROJECT_PERIMETER_UUID", localPerimeter.uuid);
    }
  },

  async addAssetsToProjectPerimeter({ dispatch, rootGetters }, assetsData) {
    const request = new amosPerimeterApi.AddProjectAssetsRequest();

    request.setProjectUuid(rootGetters["project/getProjectUuid"]);
    request.setSitesList(assetsData.sites ?? []);
    request.setBuildingsList(assetsData.buildings ?? []);
    request.setStoreysList(assetsData.storeys ?? []);
    request.setZonesList(assetsData.zones ?? []);
    request.setSpacesList(assetsData.spaces ?? []);
    request.setComponentsList(assetsData.components ?? []);
    request.setSubcomponentsList(assetsData.subcomponents ?? []);

    try {
      const response = await amosPerimeterClient.addProjectAssets(request, {});
      await dispatch(
        "storeProjectAssetsFromResponse",
        response.toObject().aosObservablesList
      );
      return response.toObject();
    } catch (error) {
      console.error(`Error Adding asset to project Perimeter: ${error}`);
    }
  },

  async storeProjectAssetsFromResponse(_, aosObservablesList) {
    if (!aosObservablesList.length) return;
    await formatAndInsertAssets(aosObservablesList);

    await formatAndInsertObservables(aosObservablesList);
  },

  /**
   * @param selectionUuids - an object with structures as key and array of AOS items uuids as value
   * {
   *  site: [aos_uuid1, aos_uuid2],
   *  building: [aos_uuid3],
   *  storey: [storey_uuid4]
   * }
   */
  async removeAssetsFromProjectPerimeter({ rootGetters }, selection) {
    const request = new amosPerimeterApi.RemoveProjectAssetsRequest();
    request.setProjectUuid(rootGetters["project/getProjectUuid"]);
    request.setAosObservablesList(Object.values(selection).flat() ?? []);
    const response = await amosPerimeterClient.removeProjectAssets(request, {});
    return response.toObject();
  },

  // Removes the {Model}Asset of each selected items in the navAmos, based on his aos item uuid
  async removeProjectAssets(
    { dispatch, rootState, rootGetters },
    structureSelections
  ) {
    const breadcrumbsEntries = Object.entries(
      rootGetters["aos/breadcrumbSelectionsAsUuids"]()
    );

    // Filter the breadcrumbSelections based on the structure checkbox selection made in the RemoveFromAmosModal
    const breadcrumbEntriesFiltered = breadcrumbsEntries.filter(([key]) =>
      structureSelections.includes(key)
    );
    const filteredBreadcrumbs = Object.fromEntries(breadcrumbEntriesFiltered);

    const { aosObservablesList: aosItems } = await dispatch(
      "removeAssetsFromProjectPerimeter",
      filteredBreadcrumbs
    );

    const { aosEventBus } = useAosEventBus();

    await Promise.all(
      Object.entries(ASSET_ITEMS).map(async ([moduleName, structure]) => {
        const Model = structure.modelClass();

        const query = Model.query().where(
          "aosItem",
          (aosItem) => !aosItems.includes(aosItem)
        );
        if (!query.count()) return;
        await Model.delete((model) => !aosItems.includes(model.aosItem));
        await AosObservable.delete(
          (observable) => !aosItems.includes(observable.aosItem)
        );
        const currentCount =
          rootState.aos[moduleName][`${moduleName}TotalCount`];
        const newTotalCount = currentCount - query.count();

        aosEventBus.$emit(
          utils.aosEventsConst.AOS_EVENTS_LISTENED[
            "UPDATE_STRUCTURE_TOTAL_ITEMS_COUNT"
          ].eventName,
          moduleName,
          newTotalCount < 0 ? 0 : newTotalCount
        );
      })
    );

    return aosItems;
  },

  async resetState() {
    await Promise.all([
      SiteAsset.deleteAll(),
      BuildingAsset.deleteAll(),
      StoreyAsset.deleteAll(),
      ZoneAsset.deleteAll(),
      SpaceAsset.deleteAll(),
      ComponentAsset.deleteAll(),
      SubcomponentAsset.deleteAll(),
      AosObservable.deleteAll(),
      AssetTag.deleteAll(),
    ]);
  },

  async saveAssetTags(context, { tags, itemUuid, itemClass = "building" }) {
    const request = new socioGrpcClient.amos_back.tags.SetItemTagsRequest();
    request.setItemClass(itemClass);
    request.setItemUuid(itemUuid);
    request.setTagsList(
      tags.map((tag) => {
        const tagRequest =
          new socioGrpcClient.amos_back.tags.SmallTagCreateRequest();
        tagRequest.setLabel(tag.label);
        tagRequest.setEditable(tag.editable);
        tagRequest.setChildrenList(
          tag.children.map((child) => child.label ?? child)
        );
        return tagRequest;
      })
    );
    const response =
      await socioGrpcClient.amos_back.tags.TagControllerPromiseClient.setItemTags(
        request,
        {}
      );
    const tagsData = response.toObject().resultsList.map((tag) => ({
      ...tag,
      tagData: tag.tag,
      tag: tag.tag.uuid,
    }));

    const model = ASSET_ITEMS[itemClass].modelClass();

    await model
      .fields()
      .tagsData.parent.query()
      .where("item", itemUuid)
      .delete();
    await model.fields().tagsData.parent.insertOrUpdate({ data: tagsData });

    await model.update({
      where: itemUuid,
      data: {
        tags: tagsData.map((t) => t.tag),
      },
    });

    // Update asset rxdb model with new tags
    const { execOnCollection } = useRxdbCollection(`${itemClass}_asset`);
    const asset = await execOnCollection((c) => c.findOne(itemUuid).exec());
    await asset.incrementalUpdate({
      $set: { tags: tagsData.map((t) => t.tag) },
    });
  },

  // MS - INFO - 24/07/24 - Caution ! AssetTag pulled on the app start
  // but no logic for upsert in rxdb if this action is recalled elsewhere
  async fetchAssetTags({ rootGetters }) {
    const request = new socioGrpcClient.amos_back.tags.TagListCaseTagsRequest();
    request.setCaseUuid(rootGetters["project/getCurrentProject"].caseUuid);

    const response =
      await socioGrpcClient.amos_back.tags.TagControllerPromiseClient.listCaseTags(
        request,
        {}
      );
    const resultsList = response.getResultsList().map((tag) => tag.toObject());
    await AssetTag.insertOrUpdate({ data: resultsList });
  },

  async fetchDefaultAssetTags(context, { filters }) {
    const metadata = {
      filters: JSON.stringify(filters),
    };
    const request = new socioGrpcClient.amos_back.tags.TagListRequest();
    const response =
      await socioGrpcClient.amos_back.tags.TagControllerPromiseClient.list(
        request,
        metadata
      );
    const resultsList = response.getResultsList().map((tag) => tag.toObject());
    return resultsList;
  },

  async updateAssetData(
    { dispatch, rootGetters },
    { structureName, aosItemData }
  ) {
    const asset = rootGetters["asset/retrieveAssetFromAosItem"](
      structureName,
      aosItemData.uuid
    );

    dispatch(
      `${structureName}/updateCode`,
      {
        uuid: asset?.uuid,
        code: aosItemData.code,
      },
      { root: true }
    );

    if (aosItemData.tags) {
      await dispatch("saveAssetTags", {
        itemUuid: asset?.uuid,
        tags: Object.values(aosItemData.tags),
        itemClass: structureName,
      });
    }
  },
};

const mutations = {
  SET_TAGS_SELECTED: (state, tags) => {
    state.tagsSelected = tags;
  },

  SET_PROJECT_PERIMETER_UUID: (state, projectPerimeterUuid) => {
    state.projectPerimeterUuid = projectPerimeterUuid;
  },
  SET_PROJECT_PERIMETER_LOADING: (state, value) => {
    state.perimeterLoadingInReplication = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
