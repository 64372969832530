const GeneralConfig = () =>
  import(
    /* webpackChunkName: "GeneralConfig" */ "@/components/amos/visits/visit/config/GeneralConfig"
  );
const MissionConfig = () =>
  import(
    /* webpackChunkName: "MissionConfig" */ "@/components/amos/visits/visit/config/MissionConfig"
  );
const ContributorConfig = () =>
  import(
    /* webpackChunkName: "ContributorConfig" */ "@/components/amos/visits/visit/config/ContributorConfig"
  );
const ReportConfig = () =>
  import(
    /* webpackChunkName: "ReportConfig" */ "@/components/amos/visits/visit/config/ReportConfig"
  );

const CharacteristicDataConfig = () =>
  import(
    /* webpackChunkName: "CharacteristicDataConfig" */ "@/components/amos/visits/visit/config/CharacteristicDataConfig"
  );

export default [
  {
    path: "",
    name: "project-config",
    component: GeneralConfig,
  },
  {
    path: "mission-config",
    name: "mission-config",
    component: MissionConfig,
  },
  {
    path: "contributor-config",
    name: "contributor-config",
    component: ContributorConfig,
  },
  {
    path: "report-config",
    name: "report-config",
    component: ReportConfig,
  },
  {
    path: "characteristic-data-config",
    name: "characteristic-data-config",
    component: CharacteristicDataConfig,
  },
];
