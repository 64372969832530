import { Model } from "@vuex-orm/core";
import { ReferencialNode } from "@socotec.io/socio-vue-components";
import { orderBy } from "lodash";
import { localesMap } from "@/utils/const";
export class BaseModel extends Model {
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      updatedAt: this.string(""),
      createdAt: this.string(""),
      displayed: this.boolean(true),
    };
  }

  static async insertOrUpdate(payload) {
    const data = await super.insertOrUpdate(payload);
    this.commit((state) => {
      state.isInserted = true;
    });
    return data;
  }

  static async insert(payload) {
    const data = await super.insert(payload);
    this.commit((state) => {
      state.isInserted = true;
    });
    return data;
  }
}

export class BaseMissionModel extends Model {
  static primaryKey = "code";

  static fields() {
    return {
      code: this.attr(""),
      label: this.attr(""),
      description: this.attr(""),
    };
  }
}

export class BaseAdminGroupModel extends BaseModel {
  static state() {
    return {
      isInserted: false, // flag to check if the model is inserted in the store after rxdb-worker end
    };
  }

  static fields() {
    return {
      ...super.fields(),
      uuid: this.attr(""),
      label: this.attr(""),
      ordering: this.attr(999),
      isArchived: this.boolean(false),

      // i18n fields. Normally you should never have to read these fields directly.
      // They are only here to generate rxdb schemas.
      labelFr: this.string(""),
      labelEnGb: this.string(""),
    };
  }
}

export class AmosReferencial extends BaseModel {
  static fields() {
    return {
      ...super.fields(),
      referencialNodePath: this.string(""),
      referencialNodeLabel: this.string(""),
      referencialNodeUuid: this.string(""),
      referencialVersion: this.string(""),
      referencialNodeLevel: this.number(),
      numchild: this.number(0),
      description: this.string(""),

      // i18n fields. Normally you should never have to read these fields directly.
      // They are only here to generate rxdb schemas.
      referencialNodeLabelFr: this.string(""),
      referencialNodeLabelEnGb: this.string(""),
      descriptionFr: this.string(""),
      descriptionEnGb: this.string(""),
    };
  }
}

export class BaseReferencialModel extends ReferencialNode {
  static state() {
    return {
      aggregatesMap: {},
      notFoundItems: new Set(),
    };
  }

  static fields() {
    return {
      ...super.fields(),
      referencialName: this.string(""),
      fullLabel: this.string(""),
      breadcrumbs: this.attr([]),
      originalPath: this.string(""),
    };
  }

  static async insertOrUpdate(payload) {
    const formattedData = this.formatData(payload);
    const ret = await super.insertOrUpdate({ data: formattedData });
    this.onInsertionDone();
    return ret;
  }

  static async insert(payload) {
    const formattedData = this.formatData(payload);
    const ret = await super.insert({ data: formattedData });
    this.onInsertionDone();
    return ret;
  }

  static formatData({ data }) {
    if (!data?.length) return [];
    // sort only once before insertion, to avoid sorting on every query
    // only originalPath can be used to sort properly. Path being a string, it will sort alphabetically
    return orderBy(data, ["originalPath"], ["asc"]);
  }

  static onInsertionDone() {
    const modelStates =
      this.store().state[this.database().namespace][this.entity];
    if (modelStates.notFoundItems?.size) {
      console.error(
        `Some ${this.entity} items were not found in the store`,
        modelStates.notFoundItems
      );
    }
    modelStates.notFoundItems = new Set();
  }

  static setSmallestQuotationValue(nodeId, value) {
    this.commit((state) => {
      if (!state.aggregatesMap[nodeId]) {
        state.aggregatesMap[nodeId] = {};
      }
      state.aggregatesMap[nodeId].smallestQuotationValue = value;
    });
  }

  static setRelatedReferencialTypeCount(nodeId, value) {
    this.commit((state) => {
      if (!state.aggregatesMap[nodeId]) {
        state.aggregatesMap[nodeId] = {};
      }
      state.aggregatesMap[nodeId].relatedReferencialTypeCount = value;
    });
  }

  static decrementReferencialTypeCount(nodeId) {
    this.commit((state) => {
      if (!state.aggregatesMap[nodeId]) {
        state.aggregatesMap[nodeId] = {};
      }
      state.aggregatesMap[nodeId].relatedReferencialTypeCount -= 1;
    });
  }

  static clearAggregateState() {
    this.commit((state) => {
      state.aggregatesMap = {};
    });
  }

  static getAggregatesByNodeUuid(nodeId) {
    // kind of a mapState for models
    const modelStates =
      this.store().state[this.database().namespace][this.entity];
    return modelStates.aggregatesMap[nodeId];
  }

  getLocale() {
    const user = this.$store().getters["user/getCurrentUser"];
    const userLang = user.language;
    return localesMap[userLang];
  }

  getAmosEntity() {
    const referenicalEntityName = this.constructor.entity;
    const amosReferencialsMap = {
      typologyTypeNodes: "typologyTypes",
      dispositionNodes: "dispositionTypes",
    };
    const amosEntityName = amosReferencialsMap[referenicalEntityName];
    if (!amosEntityName) return null;

    const amosEntity = this.$store().state.entities[amosEntityName];
    return amosEntity;
  }

  makeFullLabel(localeSuffix, amosEntity) {
    if (!localeSuffix) return `${this.path} ${this.label}`;
    if (!amosEntity) return `${this.path} ${this.label}`;

    const amosInstance = amosEntity.referencialUuidMap[this.uuid];
    if (!amosInstance) return `${this.path} ${this.label}`;

    const resolvedLabel = amosInstance[`referencialNodeLabel${localeSuffix}`];
    if (!resolvedLabel) return `${this.path} ${this.label}`;
    return `${this.path} ${resolvedLabel}`;
  }

  makeNodeBreadcrumbs() {
    const locale = this.getLocale();
    const amosEntity = this.getAmosEntity();

    const pathAsArr = this.path.split(".");
    if (!pathAsArr.length) return [];

    pathAsArr.pop();
    const breadcrumbs = [this.makeFullLabel(locale, amosEntity)];
    while (pathAsArr.length) {
      const parentItemQuery = this.constructor
        .query()
        .where((node) => node.path === pathAsArr.join("."));
      if (!parentItemQuery.exists()) break;

      const parentItem = parentItemQuery.first();
      breadcrumbs.push(parentItem.makeFullLabel(locale, amosEntity));
      pathAsArr.pop();
    }

    return breadcrumbs;
  }
}
