import { Relation } from "@/models/Relation";
import { useService } from "@/setup/useService";
import { RelationController } from "@socotec.io/socio-grpc-api/connect/services/model_linker/model_linker_svc_pb";

const relationClient = useService(RelationController);

const state = {
  appId: null,
  appInfo: null,
};

const getters = {
  getAppId: (state) => {
    return state.appId;
  },
};

const actions = {
  async bulkCreateOrUpdateRelations(_, { relations, clearExisting = false }) {
    try {
      await relationClient.bulkCreateOrUpdate({
        relations,
        clearExisting,
      });
    } catch (e) {
      console.error("Cannot bulk create - update relations");
      throw e;
    }
  },

  async bulkRemoveRelations(_, { appServiceId, entityName, entityId }) {
    try {
      await relationClient.bulkRemoveFromEntity({
        appServiceId,
        entityId,
        entityName,
      });
    } catch (e) {
      console.error(`Cannot bulk remove relations : ${entityName} ${entityId}`);
      throw e;
    }
  },

  async findEntitiesByTargetIds(
    _,
    {
      appServiceId,
      entityName,
      targetIds,
      targetEntityName = null,
      targetService = null,
      matchAllRelations = false,
    }
  ) {
    const headers = {
      filters: JSON.stringify({
        app_service_id: appServiceId,
        app_entity_name: entityName,
        ...(targetEntityName && { target_entity_name: targetEntityName }),
        ...(targetService && { target_service: targetService }),
      }),
    };

    try {
      const response = await relationClient.findEntitiesByTargetIds(
        { uuids: targetIds, matchAllRelations },
        { headers }
      );
      const { results } = response;
      return results.map((r) => r.appEntityId);
    } catch (e) {
      console.error("Cannot make call to find entities by target ids");
      throw e;
    }
  },

  async fetchEntityRelations(_, { filters, populateORM = false }) {
    const request = {
      Filters: filters,
    };
    const response = await relationClient.list(request);
    const { results } = response;
    const relations = results || [];

    if (populateORM) {
      await Relation.insert({
        data: relations,
      });
    }
    return relations;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};
