import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { ReferencialNode, utils } from "@socotec.io/socio-vue-components";
import {
  baseExcludedFields,
  archiveExcludedFields,
  referencialVersionNames,
} from "@/utils/const.js";
import requestFactory from "../factory";

const { conditionalsListToObject, grpcPropertiesToRename } =
  utils.referencialUtils;

const state = {
  referencialVersion: {
    amos_disposition: null,
    aos_component_classification: null,
    amos_doc_review: null,
  },
};

const getters = {
  getReferencialVersion: (state) => (referencialName) => {
    return state.referencialVersion[referencialName];
  },
};

const actions = {
  // MS - INFO - 24/07/24 - Caution ! No logic for insert in rxdb
  referencialFactory:
    ({
      requestParams,
      ModelClass = ReferencialNode,
      handleResponse = (x) => x,
      transformResponse = null,
      grpcAction = "flatList",
      insertMethod = "insert",
    }) =>
    async (_, { filters = {}, extraParams = {} }) => {
      const metadata = { filters: JSON.stringify(filters) };
      const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.referencial.referencial_services.VersionCodesRequest,
        { ...requestParams, ...extraParams }
      );
      const response =
        await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient[
          grpcAction
        ](request, metadata);
      let resultsList = response.toObject().resultsList;
      // Check if a custom function is defined to transform response
      if (transformResponse instanceof Function) {
        resultsList = await transformResponse(response);
      }
      handleResponse(resultsList);
      ModelClass[insertMethod]({ data: resultsList });
    },

  async fetchReferencialVersion({ commit }, data) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.VersionRequest,
      data
    );
    const response =
      await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.version(
        request,
        {}
      );
    commit("SET_REFERENCIAL_VERSION", {
      versionData: response.toObject(),
      referencialFetched: data.referencialFetched,
    });
  },

  async allConditionalsListToJSON({ dispatch }, referencialNodeResult) {
    const response = await conditionalsListToObject(
      { dispatch },
      referencialNodeResult.getResultsList()
    );
    return response;
  },

  async getModifiedReferencialNodes({ rootGetters }, params) {
    const client =
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeControllerPromiseClient;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeGetModifiedRequest,
      params
    );
    const currentUser = rootGetters["user/getCurrentUser"];
    request.setModifiedByUuid(currentUser.usermanagementUuid);
    const response = await client.getModified(request, {});
    return response.toObject().resultsList;
  },

  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤ RETRIEVE ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤

  retrieveReferencialNode: requestFactory.actions.retrieveFactory(
    ReferencialNode,
    socioGrpcClient.referencial.referencial_services
      .ReferencialNodeCodesRequest,
    socioGrpcClient.referencial.referencial_services
      .ReferencialNodeControllerPromiseClient
  ),

  async getLastReferencialExportDate(context, params) {
    const client =
      socioGrpcClient.referencial.referencial_services
        .CSVExportReferencialControllerPromiseClient;

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.VersionRequest,
      params
    );

    const response = await client.getDateLastReferencialExport(request, {});

    return response.getExportDate();
  },

  async getReferencialLastUpdateDate(context, params) {
    const excludedField = [
      "versionName",
      "versionNumber",
      "referencialType",
      "projectUuid",
    ];
    const client =
      socioGrpcClient.referencial.referencial_services
        .ReferencialUpdateControllerPromiseClient;

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .ReferencialUpdateLastUpdateRequest,
      params,
      excludedField
    );
    const response = await client.lastUpdate(request, {});
    return response.toObject().lastUpdated;
  },

  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤ CREATE / UPDATE ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤

  async createReferencialNode({ rootGetters }, { nodeData, nodeModelClass }) {
    const referencialNodeRequest = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.ReferencialNodeRequest,
      nodeData,
      baseExcludedFields
    );

    referencialNodeRequest.setModifiedByUuid(
      rootGetters["user/getCurrentUser"].usermanagementUuid
    );

    const referencialNodeResponse =
      await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.create(
        referencialNodeRequest,
        {}
      );
    const result = referencialNodeResponse.toObject();

    result.isDisplay = true;
    result.rootNodePath = nodeData.rootNodePath;
    return await nodeModelClass.insert({
      data: result,
    });
  },

  async updateReferencialNode({ rootGetters }, { nodeData, nodeModelClass }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.ReferencialNodeRequest,
      nodeData,
      baseExcludedFields
    );
    request.setModifiedByUuid(
      rootGetters["user/getCurrentUser"].usermanagementUuid
    );
    const response =
      await socioGrpcClient.referencial.referencial_services.ReferencialNodeControllerPromiseClient.update(
        request,
        {}
      );
    await nodeModelClass.insertOrUpdate({
      where: nodeData.uuid,
      data: { ...response.toObject() },
    });
  },

  async moveReferencialNode({ rootGetters }, { data, nodeModelClass }) {
    const client =
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeControllerPromiseClient;
    const request =
      new socioGrpcClient.referencial.referencial_services.ReferencialNodeUpdateMoveRequest();
    request.setTarget(data.target);
    request.setCode(data.code);
    request.setUuid(data.node.uuid);
    const user = rootGetters["user/getCurrentUser"];
    request.setModifiedByUuid(user.usermanagementUuid);
    const response = await client.updateMove(request, {});
    await nodeModelClass.insertOrUpdate({
      where: data.node.uuid,
      data: { ...response.toObject() },
    });
  },

  async postDraftToCurrent({ rootGetters }, data) {
    const client =
      socioGrpcClient.referencial.referencial_services
        .ReferencialUpdateControllerPromiseClient;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .ReferencialUpdatePostDraftToCurrentRequest,
      data
    );
    const user = rootGetters["user/getCurrentUser"];
    request.setMigratedByUuid(user.usermanagementUuid);
    const response = await client.postDraftToCurrent(request, {});
    return response;
  },

  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤ ARCHIVE ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤
  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤

  async archiveReferencialNode({ rootGetters }, { nodeData, nodeModelClass }) {
    const client =
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeControllerPromiseClient;
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services
        .ReferencialNodeArchiveReferencialNodeRequest,
      nodeData,
      archiveExcludedFields
    );

    request.setModifiedByUuid(
      rootGetters["user/getCurrentUser"].usermanagementUuid
    );
    const response = await client.archiveReferencialNode(request, {});

    const { renameKeys } = utils.grpc;
    const updatedReferencialNode = renameKeys(
      grpcPropertiesToRename,
      response.toObject()
    );

    return await nodeModelClass.update({
      where: updatedReferencialNode.uuid,
      data: {
        isArchived: updatedReferencialNode.isArchived,
      },
    });
  },

  async exportCSVRequest(context, payload) {
    const { referencialParams } = payload;
    const client =
      socioGrpcClient.referencial.referencial_services
        .CSVExportReferencialControllerPromiseClient;

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.referencial.referencial_services.VersionRequest,
      {
        applicationName: referencialParams.value.applicationName,
        versionName: referencialVersionNames.CURRENT,
        referencialName: referencialParams.value.referencialName,
      }
    );
    return { client, request };
  },
};

const mutations = {
  SET_REFERENCIAL_VERSION: (state, data) => {
    if (!data) {
      for (const version in state.referencialVersion) {
        state.referencialVersion[version] = null;
      }
      return;
    }
    state.referencialVersion[data.referencialFetched] = data.versionData;
  },
  SET_CUSTOM_REFERENCIAL_NAME: (state, data) => {
    state.referencialVersion[data.referencialFetched].customReferencialName =
      data.versionData.customReferencialName;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
