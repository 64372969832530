import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import {
  notifications as NotificationModule,
  exportLoader as exportLoaderModule,
  bimdata as BimdataModule,
  Agency,
  AgencyGroup,
  Client,
  ReportTemplate,
  DocumentType,
  DocumentStatus,
  ReferencialNode,
  ContributorBatch,
  Batch,
  Role,
  Company,
  Drawing,
  ArchiveBimdata,
  CompanyProjectRole,
  setTelemetryUser,
} from "@socotec.io/socio-vue-components";
import { RapsotecNodeAmos } from "@/models/RapsotecNode";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";

import { SiteTag } from "@/models/SiteAsset";
import { BuildingTag } from "@/models/BuildingAsset";
import { Relation } from "@/models/Relation";
import { UserPerimeter } from "@/models/UserPerimeter";
import {
  SiteAsset,
  BuildingAsset,
  StoreyAsset,
  ZoneAsset,
  SpaceAsset,
  StandByReport,
  ComponentAsset,
  SubcomponentAsset,
  AssetTag,
  Observation,
  ObservationStatement,
  StatementType,
  Quotation,
  QuotationGroup,
  QuotationGroupType,
  QuotationValue,
  AnalyticalAxis,
  DocumentImageType,
  ActorTypology,
  Action,
  Priority,
  Periodicity,
  ActionType,
  RiskAssessment,
  Mission,
  SubMission,
  Project,
  DocumentReview,
  Scenario,
  User,
  ExpenseOverTime,
  Document,
  YearlyBudget,
  ProjectConfig,
  PriorityGroup,
  PriorityGroupPriorityPivot,
  VentilationGroup,
  DifferabilityGroup,
  ROIGroup,
  ProjectionGroup,
  CostScaleGroup,
  SourcePricingGroup,
  Case,
  Ventilation,
  ROI,
  Differability,
  Projection,
  CostScale,
  SourcePricing,
  AosObservable,
  ProjectConfigAnalyticalAxis,
  AmosComponentType,
  AmosAosObjectType,
  Disposition,
  DispositionType,
  DispositionNode,
  ReportConfigGraphic,
  ReportTemplateType,
  ActionStatus,
  QuotationAnalyticalAxis,
  LabelCertificationLifecycle,
  LabelCertificationType,
  LabelCertificationLabel,
  LabelCertificationVersion,
  LabelCertificationPanel,
  LabelCertificationLevel,
  Glossary,
  SurveyRule,
  ReportText,
  TypologyType,
  TypologyTypeNode,
  RiskAssessmentGroup,
  Report,
  ReferencialCtNode,
  CtNodeObservation,
  TechnicalAdviceReferencial,
  Contributor,
  AmosDocument,
  ObservationHistory,
  CachedProject,
  FunctionalRequirement,
  Task,
  ModelVersion,
  BIMDataPin,
} from "@/models";
import BuildingCharacteristic from "@/models/BuildingCharacteristic";

// Vuex OIDC
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings } from "@/setup/OIDCSettings";

// Client
import { initAmosAuth } from "@/setup/socioGrpcClient";
import { initDocumentAuth } from "@/setup/restDocumentClient";
import { stonlyIdentifyWidget } from "@/api/stonlyIdentifyWidget";

// Store module
import UserModule from "./modules/user";
import ReportModule from "./modules/reports";
import StandByReportModule from "./modules/standbyreport";
import ProjectModule from "./modules/project";
import ClientModule from "./modules/client";
import CaseModule from "./modules/case";
import DocumentModule from "./modules/document";
import AssetModule from "./modules/asset";
import ContributorModule from "./modules/contributor";
import ActorTypologyModule from "./modules/actorTypology";
import RoleModule from "./modules/roles";
import CompanyModule from "./modules/company";
import ObservationModule from "./modules/observation";
import SiteModule from "./modules/site";
import BuildingModule from "./modules/building";
import AmosAdminModule from "./modules/amosAdmin";
import StoreyModule from "./modules/storey";
import ZoneModule from "./modules/zone";
import SpaceModule from "./modules/space";
import ComponentModule from "./modules/component";
import SubcomponentModule from "./modules/subcomponent";
import MissionModule from "./modules/mission";
import DocumentReviewModule from "./modules/documentReview";
import ArbitrationModule from "./modules/arbitration";
import AmosCharacteristicModule from "./modules/amosCharacteristic";
import ProjectConfigModule from "./modules/projectConfig";
import ActionModule from "./modules/action";
import AmosAosModule from "./modules/amosAos";
import DispositionModule from "./modules/disposition";
import ReferencialModule from "./modules/referencial";
import LabelCertificationModule from "./modules/labelCertification";
import DraftObservationModule from "./modules/draftObservation/index";
import interfaceModule from "./modules/interface";
import ctNodeObservationModule from "./modules/ctNodeObservation";
import TechnicalAdviceReferencialModule from "./modules/technicalAdviceReferencial";
import RelationModule from "./modules/relations";
import PinModule from "./modules/pin";
import CacheVisitSettingsModule from "./modules/cacheVisitSettings";
import ManagementModule from "./modules/management";
import TaskModule from "./modules/task";
import VisioneuseModule from "./modules/visionneuse.js";
import ResyncModule from "./modules/serverSync.js";

// Auth hooks
import { tryRefreshSessionOrRedirect } from "@/setup/auth-hooks";
import { amosAdminModels } from "@/utils/amosAdmin";
import RxDBWorker from "@/rxdb/worker";
import { ObjectTypeStatement } from "@/rxdb/class/ObjectTypeStatement.js";

Vue.use(Vuex);
const database = new VuexORM.Database();

// AMOS models
// INFO - L.G. - 10/10/2022 - Do not change the order of the models
database.register(User);
database.register(Project);
database.register(Case);
database.register(AnalyticalAxis);
database.register(ObservationStatement);
database.register(Priority);
database.register(Periodicity);
database.register(Action);
database.register(Observation);
database.register(SiteAsset);
database.register(BuildingAsset);
database.register(StoreyAsset);
database.register(ZoneAsset);
database.register(SpaceAsset);
database.register(ComponentAsset);
database.register(SubcomponentAsset);
database.register(AssetTag);
database.register(StatementType);
database.register(Quotation);
database.register(QuotationGroup);
database.register(QuotationGroupType);
database.register(QuotationValue);
database.register(ActionType);
database.register(RiskAssessment);
database.register(Mission);
database.register(SubMission);
database.register(DocumentReview);
database.register(Scenario);
database.register(ExpenseOverTime);
database.register(Document);
database.register(YearlyBudget);
database.register(ProjectConfig);
database.register(AosObservable);
database.register(ProjectConfigAnalyticalAxis);
database.register(PriorityGroup);
database.register(PriorityGroupPriorityPivot);
database.register(VentilationGroup);
database.register(DifferabilityGroup);
database.register(ROIGroup);
database.register(CostScaleGroup);
database.register(ProjectionGroup);
database.register(SourcePricingGroup);
database.register(Ventilation);
database.register(ROI);
database.register(Differability);
database.register(Projection);
database.register(CostScale);
database.register(SourcePricing);
database.register(AmosComponentType);
database.register(AmosAosObjectType);
database.register(Disposition);
database.register(DispositionType);
database.register(DispositionNode);
database.register(ReportConfigGraphic);
database.register(ReportTemplateType);
database.register(ActionStatus);
database.register(QuotationAnalyticalAxis);
database.register(LabelCertificationLifecycle);
database.register(LabelCertificationType);
database.register(LabelCertificationLabel);
database.register(LabelCertificationVersion);
database.register(LabelCertificationPanel);
database.register(LabelCertificationLevel);
database.register(Glossary);
database.register(ReportText);
database.register(SurveyRule);
database.register(TypologyType);
database.register(TypologyTypeNode);
database.register(RiskAssessmentGroup);
database.register(Report);
database.register(DocumentImageType);
database.register(ActorTypology);
database.register(ReferencialCtNode);
database.register(CtNodeObservation);
database.register(TechnicalAdviceReferencial);
database.register(BuildingCharacteristic);
database.register(ObservationHistory);
database.register(Relation);
database.register(AmosDocument);
database.register(CachedProject);
database.register(FunctionalRequirement);
database.register(UserPerimeter);
database.register(Task);
database.register(BIMDataPin);

// SocioVueComponent models
database.register(Agency);
database.register(AgencyGroup);
database.register(ReportTemplate);
database.register(StandByReport);
database.register(Client);
database.register(ReferencialNode);
database.register(DocumentStatus);
database.register(DocumentType);
database.register(Contributor);
database.register(ContributorBatch);
database.register(Batch);
database.register(Role);
database.register(Company);
database.register(SiteTag);
database.register(BuildingTag);
database.register(RapsotecNodeAmos);
database.register(Drawing);
database.register(ArchiveBimdata);
database.register(CompanyProjectRole);

// Plugins
export const rxdbFieldItems = {
  conditionalDisplay: { type: "object" },
  conditionalTitles: { type: "object" },
  parcelCodesList: { type: "object" },
  roles: { type: "object" },
  surface: { type: "object" },
  actors: { type: "object" },
};

export const rxdbFieldTypes = {
  ordering: "number",
  level: "number",
  displayed: "boolean",
  isArchived: "boolean",
  siteCharacteristics: "object",
  buildingCharacteristics: "object",
  infrastructureCharacteristics: "object",
  storeyCharacteristics: "object",
  zoneCharacteristics: "object",
  spaceCharacteristics: "object",
  componentCharacteristics: "object",
  buildingTypology: "object",
  code: ["string", "number"],
  uniqueCode: ["string", "number"],
  value: ["string", "number", "object", "array", "boolean"],
};

export const rxdbModels = [
  Agency,
  AgencyGroup,
  AmosAosObjectType,
  AmosComponentType,
  AosObservable,
  ArchiveBimdata,
  AssetTag,
  SiteTag,
  BuildingTag,
  BuildingAsset,
  CachedProject,
  Case,
  Client,
  ComponentAsset,
  DispositionNode,
  Disposition,
  DocumentReview,
  ReferencialCtNode,
  CtNodeObservation,
  Action,
  Document,
  Drawing,
  Observation,
  BIMDataPin,
  Project,
  ProjectConfig,
  ProjectConfigAnalyticalAxis,
  RapsotecNodeAmos,
  ReferencialCtNode,
  SiteAsset,
  SpaceAsset,
  StoreyAsset,
  SubMission,
  TypologyTypeNode,
  User,
  UserPerimeter,
  ZoneAsset,
  DocumentType,
  DocumentImageType,
  ObservationStatement,
  ModelVersion,
  Periodicity,

  // not a vuex-orm model, still needed for rxdb collection
  ObjectTypeStatement,
]
  .concat(amosAdminModels())
  .concat([
    AosVuexOrmModel["AosBuilding"],
    AosVuexOrmModel["AosComponent"],
    AosVuexOrmModel["AosComponentType"],
    AosVuexOrmModel["AosComponentTypeProperty"],
    AosVuexOrmModel["AosTypology"],
    AosVuexOrmModel["AosPropertyDefinition"],
    AosVuexOrmModel["AosComponentProperty"],
    AosVuexOrmModel["AosLocation"],
    AosVuexOrmModel["AosSite"],
    AosVuexOrmModel["AosSpace"],
    AosVuexOrmModel["AosStorey"],
    AosVuexOrmModel["AosZone"],
    AosVuexOrmModel["AosComponentNode"],
  ]);

export default new Vuex.Store({
  state: {
    appIsLoading: false,
    appIsOnline: navigator.onLine,
  },
  getters: {
    getOfflineStatus: (state) => !state.appIsOnline,
  },
  actions: {},
  mutations: {
    SET_APP_IS_LOADING(state, bool) {
      state.appIsLoading = bool;
    },

    SET_APP_ONLINE: (state, bool) => {
      state.appIsOnline = bool;
      if (window.Worker) {
        const worker = RxDBWorker.getInstance();
        worker.postMessage({
          messageName: "setOnlineStatus",
          args: {
            status: bool,
          },
        });
      }
    },
  },
  modules: {
    oidc: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      {
        userLoaded: (user) => {
          initAmosAuth(user.access_token);
          initDocumentAuth(user.access_token);
          setTelemetryUser(user);
          stonlyIdentifyWidget(user.profile.email, user.profile.name);
        },
        userUnloaded: () => console.log("OIDC user is unloaded"),
        accessTokenExpiring: () => console.log("Access token will expire"),
        accessTokenExpired: async () => {
          console.log("Access token did expire");
          await tryRefreshSessionOrRedirect();
        },
        silentRenewError: () => console.log("OIDC user is unloaded"),
        automaticSilentRenewError: (payload) =>
          console.log("Automatic silent renew failed.", payload.error),
        userSignedOut: () => console.log("OIDC user is signed out"),
        oidcError: (payload) =>
          console.log(`An error occured at ${payload.context}:`, payload.error),
      }
    ),
    notifications: NotificationModule,
    exportLoader: exportLoaderModule,
    user: UserModule,
    site: SiteModule,
    building: BuildingModule,
    storey: StoreyModule,
    zone: ZoneModule,
    space: SpaceModule,
    component: ComponentModule,
    subcomponent: SubcomponentModule,
    report: ReportModule,
    standByReport: StandByReportModule,
    project: ProjectModule,
    case: CaseModule,
    client: ClientModule,
    document: DocumentModule,
    asset: AssetModule,
    contributor: ContributorModule,
    actorTypology: ActorTypologyModule,
    role: RoleModule,
    company: CompanyModule,
    observation: ObservationModule,
    amosAdmin: AmosAdminModule,
    mission: MissionModule,
    documentReview: DocumentReviewModule,
    arbitration: ArbitrationModule,
    amosCharacteristic: AmosCharacteristicModule,
    projectConfig: ProjectConfigModule,
    action: ActionModule,
    amosAos: AmosAosModule,
    disposition: DispositionModule,
    referencial: ReferencialModule,
    labelCertification: LabelCertificationModule,
    draftObservation: DraftObservationModule,
    interface: interfaceModule,
    ctNodeObservation: ctNodeObservationModule,
    TechnicalAdviceReferencial: TechnicalAdviceReferencialModule,
    relations: RelationModule,
    pin: PinModule,
    cacheVisitSettings: CacheVisitSettingsModule,
    management: ManagementModule,
    task: TaskModule,
    bimdata: BimdataModule,
    visionneuse: VisioneuseModule,
    serverSync: ResyncModule,
  },
  plugins: [VuexORM.install(database)],
});
