import { Action, Periodicity } from "@/models/";
import requestFactory from "../factory";
import { useService } from "@/setup/useService";
import { ActionController } from "@socotec.io/socio-grpc-api/connect/services/amos_back/observation_pb";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { cloneDeep } from "lodash";
import { useRxdbCollection } from "@/composables/useRxdbCollection.js";
import RxDB, { makeSchemaCompliantKeepingKeys } from "@/rxdb/utils";

const actionClient = useService(ActionController);

const {
  amos_back: { observation: observationApi },
} = socioGrpcClient;

const legacyClient = observationApi.ActionControllerPromiseClient;

const state = {};

const getters = {
  getActions: () => {
    return Action.query()
      .where("isArchived", false)
      .orderBy("createdAt", "desc")
      .orderBy("date")
      .withAll()
      .get();
  },
  observationsActionsCount: () => (observationUuids) => {
    return Action.query()
      .withAll()
      .where((action) => {
        return action.observationsList.some((observation) => {
          return observationUuids.includes(observation);
        });
      })
      .count();
  },
  getBudgetedActions: () => {
    return Action.query()
      .where("status", "TODO")
      .has("expensesOverTimeList", ">", 0)
      .withAllRecursive()
      .get();
  },
};
const actions = {
  async updateAction(_, payload) {
    const db = RxDB.getInstance();

    let formatedPayload = cloneDeep(payload);
    const payloadTransformations = {
      periodicity: () =>
        formatedPayload.periodicityData
          ? {
              ...formatedPayload.periodicityData,
              number: parseInt(formatedPayload.periodicityData.number),
            }
          : null,
      priority: () =>
        formatedPayload.priority?.uuid || formatedPayload.priority,
      contributors: () =>
        formatedPayload.contributors?.map((contributor) => ({
          contributorUuid: contributor,
        })) || [],
    };

    Object.keys(payloadTransformations).forEach((key) => {
      if (key in formatedPayload) {
        formatedPayload[key] = payloadTransformations[key]();
      }
    });

    const response = await actionClient.update(formatedPayload);
    await Action.update({
      data: {
        ...response,
        periodicity: response.periodicity?.uuid,
      },
    });

    const { execOnCollection } = useRxdbCollection("action");
    const action = await execOnCollection((c) =>
      c.findOne(formatedPayload.uuid).exec()
    );

    if (action) {
      const updatedAction = makeSchemaCompliantKeepingKeys(
        response,
        db["action"].getFields()
      );
      await action.incrementalUpdate({
        $set: {
          ...updatedAction,
          periodicity: updatedAction.periodicity?.uuid,
          contributors: updatedAction.contributors.map(
            (contributor) => contributor.contributorUuid
          ),
        },
      });
    } else {
      console.warn(`No action found with UUID ${formatedPayload.uuid} in RxDB`);
    }

    if (formatedPayload.periodicity) {
      await Periodicity.insertOrUpdate({
        data: response.periodicity,
      });

      const { execOnCollection: periodicityCollection } =
        useRxdbCollection("periodicity");

      const periodicityData = makeSchemaCompliantKeepingKeys(
        response,
        db["periodicity"].getFields()
      );
      await periodicityCollection((c) => c.upsert(periodicityData));
    }
  },

  async archiveAction(_, actionUuid) {
    await actionClient.setArchiveValue({
      uuid: actionUuid,
      isArchived: true,
    });
  },

  async deleteAction(_, actionUuid) {
    await actionClient.destroy({ uuid: actionUuid });
  },

  exportActions: requestFactory.actions.exportFactory(
    legacyClient,
    observationApi.ExportRequest,
    "action"
  ),

  exportActionsArbitration: requestFactory.actions.exportFactory(
    legacyClient,
    observationApi.ExportArbitrationRequest,
    "arbitration",
    "exportArbitration"
  ),
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
