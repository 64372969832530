import store from "@/store";
import { useDocumentRelationBuilder } from "../documents/useDocumentRelationBuilder";
import { AmosAosObjectType, AosObservable, BuildingAsset } from "@/models";

export const useDocumentLinking = () => {
  const { buildDocumentRelationsRequest } = useDocumentRelationBuilder();
  const getAosItemUuid = () => {
    const projectBuildingAsset = BuildingAsset.all();
    return projectBuildingAsset.map(({ aosItem }) => aosItem);
  };

  // Takes a list of document data from socio-aos
  // documentData as {documentUuid: valueA, aosItemUuid: valueB}
  const importDocumentFromExternal = async (documentDataList) => {
    const relationsList = documentDataList.map((documentData) =>
      buildDocumentRelationsRequest({
        documentId: documentData.documentUuid,
        aosItems: { building: [documentData.aosItemUuid] },
        typologyTypes: [],
        objectTypes: [
          AmosAosObjectType.query()
            .where("label", "BUILDING") // External App's document can only be of BUILDING type. (true for sps, rapsotec)
            .first().uuid,
        ],
      })
    );
    try {
      for (const relations of relationsList) {
        await store.dispatch("relations/bulkCreateOrUpdateRelations", {
          relations: relations,
          clearExisting: true,
        });
      }
    } catch (error) {
      console.error(
        `Failed building imported document Relation in model-linker :${error}`
      );
      throw new Error("errorImportLinking");
    }
    try {
      await store.dispatch(
        "document/insertImportedDocument",
        documentDataList.map(({ documentUuid }) => documentUuid)
      );
    } catch (error) {
      console.error(
        `Failed insert imported documents in store.state :${error}`
      );
      throw new Error("errorImportReload");
    }
  };

  // Amos create some Relations models inside model-linker when creating docs
  // These Relations are between a doc and an aosItem
  // External apps (sps,rapsotec) want these docs, but are only interested about the Building/Infrastructure aosItem type.
  // When we create a document linked on something else (zone/component...), external app only want the building/infrastructure

  // this create a Relation model in model linker between the doc and the Building/infrastructure (if needs be)
  const buildExternalDocumentRelation = async (aosItems, documentUuid) => {
    if (aosItems.building?.length) return [];
    const aosItemList = Object.values(aosItems).flat(1);
    const aosItemModelList = AosObservable.query()
      .where("aosItem", (aosItem) => aosItemList.includes(aosItem))
      .get()
      .map((pObs) => pObs.aosItemData);

    const buildingUuid = [
      ...new Set(
        aosItemModelList
          .filter((aosItem) => aosItem.building && aosItem.building !== "")
          .map((aosItem) => aosItem.building)
      ),
    ];
    return buildingUuid.map((buildingUuid) => {
      return {
        appServiceId: "document",
        appEntityName: "export-document",
        appEntityId: documentUuid,
        targetService: "aos",
        targetEntityName: "building",
        targetEntityId: buildingUuid,
        linkType: "DEFAULT",
      };
    });
  };
  return {
    getAosItemUuid,
    importDocumentFromExternal,
    buildExternalDocumentRelation,
  };
};
